import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";
import React from "react";
import { ImageOverrideDropdown } from "../../ImageOverride/ImageOverride";

type ModifiersPanelProps = {
    imageOverrides: [],
    setImageOverride: Function
}


export const ModifiersPanel = ({ imageOverrides, setImageOverride }: ModifiersPanelProps) => {
    return (
        <Panel header="Modifiers" toggleable className="py-4" data-testid='Modifier-Panel'>
            <div className='container flex flex-column align-items-center justify-content-between mx-0 px-0'>
                <div className='flex flex-row flex-wrap center my-3 '>
                    {imageOverrides.map((imageOverride, index) => (
                        <div key={"Carousel " + index} className="p-col-12 p-md-4 p-sm-6 mr-2 mb-2 border-3 border-500 p-2 border-round">
                            <p className='text-xl text-primary font-main mt-1 mb-1  border-500'>
                                Carousel #{index + 1}
                                {index === 0 ?
                                    <>
                                        <Tooltip className='custom-tooltip' target=".pi-info-circle" />
                                        <i
                                            className="ml-3 pi pi-info-circle"
                                            data-pr-position="top"
                                            data-pr-tooltip="Leaving this to Default will still run the default logic and could override the image in Syndigo. If you want to leave the Carousel 1 image thats already in Syndigo, select 'KEEP EXISTING IMAGE' " />
                                    </>
                                    : null}
                            </p>

                            <ImageOverrideDropdown
                                imageOverride={imageOverride}
                                setImageOverride={(value) => setImageOverride(index, value)}
                                dataTestid={`ImageOverrideDropdown-${index}`}
                                addValue={index === 0 ? [{ label: 'KEEP EXISTING IMAGE', value: 'KEEP EXISTING IMAGE' }] : null}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Panel>
    )
}