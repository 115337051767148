import React, { useCallback, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { supabaseClient } from '../../config/supabase-client';
import { Panel } from "primereact/panel";
import { useGeneratorContext } from "../../context/GeneratorContext";
import { SelectButton, SelectButtonChangeEvent } from "primereact/selectbutton";

export const SearchThinkhausFieldsUpload = () => {
    const {
        products,
        uploadTableData, setUploadTableData,
        resultsView, setResultsView,
        isShouldFetchResults, setIsShouldFetchResults
    } = useGeneratorContext();

    const resultsViewOptions = [
        { name: "View All", value: "viewAll" },
        { name: "View Update", value: "viewUpdate" },
        { name: "View Publish", value: "viewPublish" },
        { name: "View Recent", value: "viewRecent" }
    ];

    const errorFields = (error, index) => {
        return (
            error &&
            <div>
                <Panel className='w-15rem custom-error-panel' header="Error" collapsed={true} toggleable>
                    <p className="m-0 text-left no-wrap" data-testid={`fileInState-error-text-${index}`} >
                        {error}
                    </p>
                </Panel>
            </div>
        )
    }

    const fieldsField = (text, index) => {
        return (
            <div className="nowrap h-4">
                <p>
                    {text ? text?.fields.join(', ') : 'N/A'}
                </p>
            </div>
        )
    }

    const columnConfig = {
        viewAll: [
            { field: "upc", header: "UPC" },
            { field: "product_title", header: "Product Title" },
            { field: "updated_by", header: "Updated By" },
            {
                field: "request_at",
                header: "Last Update Request Date",
                body: (rowData) => {
                    const date = new Date(rowData?.request_at);
                    return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    });
                }
            },
            { field: "is_last_update_successful", header: "Last Update Result" },
            {
                field: "update_error",
                header: "Update Error",
                body: (rowData, index) => errorFields(rowData.update_error, index)
            },
            { field: "fields", header: "Fields", body: (rowData) => rowData?.fields ? rowData?.fields.join(', ') : 'N/A' },
            { field: "publish_user_email", header: "Published By" },
            {
                field: "published_at",
                header: "Last Successful Publish Date",
                body: (rowData) => {
                    const date = new Date(rowData?.published_at);
                    return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    });
                }
            },
            {
                field: "is_last_publish_successful",
                header: "Last Publish Result",
                body: (rowData) => rowData?.is_last_publish_successful ? 'Success' : 'Failed'
            },
            {
                field: "publish_error",
                header: "Publish Error",
                body: (rowData, index) => rowData?.publish_error && errorFields(rowData?.publish_error, index)
            }
        ],
        viewUpdate: [
            { field: "upc", header: "UPC" },
            { field: "product_title", header: "Product Title" },
            { field: "update_user_email", header: "Updated By" },
            {
                field: "request_at",
                header: "Last Update Request Date",
                body: (rowData) => {
                    const date = new Date(rowData?.request_at);
                    return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    });
                }
            },
            {
                field: "is_last_update_successful",
                header: "Last Update Result",
                body: (rowData) => rowData?.is_last_update_successful ? 'Success' : 'Failed'
            },
            {
                field: "update_error",
                header: "Update Error",
                body: (rowData, index) => errorFields(rowData.update_error, index)
            },
            {
                field: "fields",
                header: "Fields",
                body: (rowData, index) => rowData?.fields && fieldsField(rowData, index)
            }
        ],
        viewPublish: [
            { field: "upc", header: "UPC" },
            { field: "product_title", header: "Product Title" },
            { field: "publish_user_email", header: "Published By" },
            {
                field: "published_at",
                header: "Last Successful Publish Date",
                body: (rowData) => {
                    const date = new Date(rowData?.published_at);
                    return date.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    });
                }
            },
            {
                field: "is_last_publish_successful",
                header: "Last Publish Result",
                body: (rowData) => rowData?.is_last_publish_successful ? 'Success' : 'Failed'
            },
            {
                field: "publish_error",
                header: "Publish Error",
                body: (rowData, index) => rowData?.publish_error && errorFields(rowData?.publish_error, index)
            }
        ],
        viewRecent: [
            { field: "upc", header: "UPC" },
            { field: "product_title", header: "Product Title" },
            {
                field: "is_last_update_successful",
                header: "Last Update Result",
                body: (rowData) => rowData?.is_last_update_successful ? 'Success' : 'Failed'
            },
            {
                field: "is_last_publish_successful",
                header: "Last Publish Result",
                body: (rowData) => rowData?.is_last_publish_successful ? 'Success' : 'Failed'
            }
        ]
    };

    const fetchResults = useCallback(async () => {
        const upcArray = products.map(product => product.UPC); // Extract UPCs from products

        // Fetch latest publish and update data by joining with thinkhaus_upload_upcs
        const { data, error } = await supabaseClient
            .from('thinkhaus_uploads_combined_view')
            .select("*")
            .in('upc', upcArray);

        if (error) {
            console.error('Error fetching data:', error);
        } else {
            // Create a lookup map for faster access
            const dataMap = new Map(data.map(product => [product.upc, product]));

            // Map the upcArray to the sorted results using the dataMap for O(1) lookups
            const sortData = upcArray.map(upc => dataMap.get(upc) || { upc }); // use the upc if there was no matching data found

            setUploadTableData(sortData); // Set the combined results to be used by the DataTable

        }
    }, [products, setUploadTableData]);

    const selectedColumns = columnConfig[resultsView];

    useEffect(() => {
        if (isShouldFetchResults) {
            fetchResults();
            setIsShouldFetchResults(false);
        }
    }, [products, fetchResults, isShouldFetchResults, setIsShouldFetchResults]);

    return (
        <div >
            <Panel header="Data Views" toggleable collapsed className='mb-2'>
                <SelectButton className="flex justify-content-center" value={resultsView} options={resultsViewOptions} optionLabel="name" onChange={(e: SelectButtonChangeEvent) => setResultsView(e.value)} />
            </Panel>
            {
                uploadTableData &&
                <DataTable value={uploadTableData} rows={products.length} className="p-datatable-sm" scrollable scrollHeight="500px">
                    {selectedColumns.map((col) => (
                        <Column
                            key={col.field}
                            field={col.field}
                            header={col.header}
                            body={col.body ? col.body : undefined} // Use custom body if defined
                            className="w-15rem max-h-2"
                            style={{
                                minWidth: '150px',  // Set the minimum width for the column
                                maxWidth: '250px'   // Optionally, set a max width if you want to limit it
                            }}
                            bodyStyle={{
                                height: '60px', // Set specific height for each cell
                                textAlign: 'left',
                                overflow: 'auto', // Handle overflow, or 'auto' for scrollbars
                                textOverflow: 'ellipsis', // Show ellipsis for overflowed text
                                maxHeight: '60px',
                                whiteSpace: 'normal'
                            }}
                        />
                    ))}
                </DataTable>
            }

        </div>
    );
}