import React, { useRef } from "react";
import { Button } from "primereact/button";
import { saveAs } from 'file-saver';
import axios from "axios";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";

type ExportButtonProps = {
  products: object[],
  fileName: string,
  wordLists?: object,
  URL?: string,
  isCSV?: boolean,
  className?: string
};

export const ExportButton = ({ products, fileName, wordLists = {}, URL = '', isCSV = false, className = '' }: ExportButtonProps) => {
  const { session } = useAuth();
  const toastRef = useRef(null);

  const sendExport = async (productsArray, wordLists) => {
    try {
      const response = await axios.post(URL + "api/download/excel/generator", { productsArray, wordLists }, {
        responseType: 'blob',
        headers: {
          "Authorization": `Bearer ${session.access_token}`,
        }
      });
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const formatFileName = fileName + '_export_' + new Date().getTime() + '.xlsx';
      saveAs(blob, formatFileName);
    } catch (error) {
      console.error("Error downloading the file", error);

      toastRef.current.show({
        severity: 'error',
        summary: 'Error downloading XLSX file',
        detail: error.message
      })
    }
  };

  const sendExportCSV = async (productsArray) => {
    try {
      // Convert products array to CSV string
      const headers = Object.keys(productsArray[0]).join(',');
      const csvRows = productsArray.map(product =>
        Object.values(product).map(value =>
          typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
        ).join(',')
      );
      const csvString = [headers, ...csvRows].join('\n');

      // Create and save the CSV file
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const formatFileName = fileName + '_export_' + new Date().getTime() + '.csv';
      saveAs(blob, formatFileName);
    } catch (error) {
      console.error("Error downloading the file", error);

      toastRef.current.show({
        severity: 'error',
        summary: 'Error downloading CSV file',
        detail: error.message
      })
    }
  }

  return (
    <>
      <Toast ref={toastRef} />
      {products.length > 0 && (
        <Button
          className={"p-button-warning " + className}
          data-pr-tooltip="Excel"
          icon="pi pi-file-excel"
          label={`Export ${isCSV ? 'CSV' : 'XLSX'}`}
          onClick={async () => isCSV ? sendExportCSV(products) : sendExport(products, wordLists)}
          data-testid="Export-Button"
          size='small'
        />
      )}
    </>
  );
} 