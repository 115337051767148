import { ProgressSpinner } from "primereact/progressspinner";

const statusTemplate = (rowData, status: object) => {
    // Ensure loading is always an array
    const statusArray = Array.isArray(status) ? status : [status];
    const switchKey = statusArray.find(item => item.upc === rowData.UPC);

    let statusIcon;
    // console.log(switchKey?.isLoading)
    if (switchKey?.isLoading === true) {
        statusIcon = <ProgressSpinner className='ml-2' style={{ width: '20px', height: '20px' }} strokeWidth="8" />
    } else {
        switch (switchKey?.result) {
            case 'Success':
                statusIcon = <i className="pi pi-verified text-green-500" style={{ fontSize: '1rem' }} data-cy={`Success-${rowData.UPC}`}></i>
                break;
            case undefined:
                break;
            case null: 
                break;
            default:
                statusIcon = <i className="pi pi-flag-fill text-red-500" style={{ fontSize: '1rem' }} data-cy={`Error-${rowData.UPC}`}></i>
                break;
        }
    }


    return (
        <div className="flex align-items-center ml-3">
            {statusIcon}
        </div>
    );
};

export default statusTemplate;