import React, { useEffect } from "react";
import { FileUpload } from "primereact/fileupload";
import { handleImport } from "./UploadButtonFuncs";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DropdownSelect } from "../DropdownSelect/DropdownSelect";

type UploadButtonProps = {
    products: object[],
    setProducts: (value: object[]) => void,
    setChoosingSheet: (value: boolean) => void,
    setSheet: (value: string) => void,
    setSheetChoices: (value: string[]) => void,
    setWb: (value: object) => void,
    choosingSheet: boolean,
    wb: any,
    sheet: any,
    sheetChoices: any
};

export const UploadButton = ({ products, setProducts, setChoosingSheet, choosingSheet, setSheet, setSheetChoices, setWb, wb, sheet, sheetChoices }: UploadButtonProps) => {

    useEffect(() => {
        if (wb && choosingSheet) { // want to execute dialog when there is a choosing sheet and choosingsheet===true
            confirm1();
        }
    }, [wb, choosingSheet])

    const confirm1 = () => {
        confirmDialog({
            group: 'headless',
            message: 'Please choose a sheet.',
            header: 'Sheet Selection',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept'
        });
    };

    return (
        <>
            {!products.length &&

                <>
                    <div className="card flex flex-wrap gap-2 justify-content-center">
                        <FileUpload
                            accept=".xlsx, .csv"
                            auto
                            className="ml-3 my-3"
                            customUpload
                            chooseLabel="Browse Files"
                            mode="basic"
                            uploadLabel="Upload Excel File"
                            uploadHandler={(e) => handleImport(e, setProducts, setWb, setChoosingSheet, setSheet, setSheetChoices)}
                            data-testid='Upload-XLSX'
                        />
                    </div> </>


            }

            <ConfirmDialog
                group="headless"
                content={({ headerRef, contentRef, footerRef, hide, message }) => (
                    <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
                        <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                            <i className="pi pi-question text-5xl"></i>
                        </div>
                        <span className="font-bold text-2xl block mb-2 mt-4" ref={headerRef as React.LegacyRef<HTMLSpanElement>}>
                            {message.header}
                        </span>
                        <p className="mb-0" ref={contentRef as React.LegacyRef<HTMLParagraphElement>}>
                            {message.message}
                        </p>
                        <div className="flex flex-fill align-items-center min-w-full" ref={footerRef as React.LegacyRef<HTMLDivElement>}>
                            <DropdownSelect
                                wb={wb}
                                sheet={sheet}
                                setSheet={setSheet}
                                setChoosingSheet={setChoosingSheet}
                                setProducts={setProducts}
                                sheetChoices={sheetChoices}
                                id='DialogDropdown'
                            />
                        </div>
                    </div>
                )}
            />

        </>
    )
};