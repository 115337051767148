import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProductTitles } from "../SyndigoSubmitButton/SyndigoSubmitButtonFuncs";
import { handlePublishClick, handlePublishDialog } from "./SyndigoPublishButtonFuncs";
import "../../App.css";
import { useAuth } from "../../context/AuthProvider";
import { Toast } from "primereact/toast";

type SyndigoPublishButtonProps = {
    products: any[],
    URL: string,
    buttonText: string,
    imageOverrides: string[],
    setPublishStatus: Function
};

export const SyndigoPublishButton = ({ products, URL, buttonText, imageOverrides, setPublishStatus }: SyndigoPublishButtonProps) => {
    const [showPublishDialog, setShowPublishDialog] = useState(false);
    const [showTitles, setShowTitles] = useState([]);

    const toast = useRef(null);

    const { session } = useAuth();

    return (
        <>
            <Toast ref={toast} />
            <Button className="p-2 p-button-rounded" onClick={() => handlePublishDialog(products, setShowPublishDialog, setShowTitles)} data-testid={buttonText}> {buttonText} </Button>

            <Dialog
                visible={showPublishDialog}
                className="Syndigo-Submit-Dialog"
                onHide={() => setShowPublishDialog(false)}
            >
                <>
                    <div className="s-title">Are you sure you want to Publish to following?</div>
                    {<ProductTitles showTitles={showTitles} />}
                    <Button
                        className="p-button-primary ml-3 my-3 align-items-end"
                        icon="pi pi-file"
                        label="Publish"
                        onClick={() => handlePublishClick({ products, URL, setShowPublishDialog, MainImageOverride: imageOverrides[0], session, setPublishStatus })}
                        data-cy-publish-button
                    />
                    <Button
                        className="p-button-danger ml-3 my-3 align-items-end"
                        icon="pi pi-ban"
                        label="Close"
                        onClick={() => setShowPublishDialog(false)}
                    />
                </>
            </Dialog>
        </>
    )
};